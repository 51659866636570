import React from "react";
import DisordSVG from "./icons/discord.svg";
import "./Discord.scss";
const DiscordBtn = () => {
  return (
    <a
      type="button"
      // href="https://discord.com/invite/nvTBbyfSbz"
      href="https://zombieholidaynft.com/"
      className="discordbtn"
      target="_blank"
      rel="noreferrer"
    >
      {/* <div> 
        <img src={DisordSVG} alt="" />
      </div>*/}
      Explore Zombie Holiday NFT
    </a>
  );
};

export default DiscordBtn;
