import React, { useEffect } from "react";
import AOS from "aos";
import "./index.scss";

export const MartleyNutshell2 = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);
  return (
    <div className="martleyNutshell2">
      <div className="component">
        <div
          className="inner-wrap"
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-duration="700"
        >
          <div className="text">
            <h4>
              At Martley Business Design, our passion is helping businesses
              bring their visions to life. We specialize in business branding,
              the cornerstone of our services, and combine it with digital
              marketing, web and app development, and expert copywriting to
              deliver comprehensive solutions. Whether you are a startup looking
              to establish a presence or a seasoned business aiming to refresh
              your brand, we provide the tools and strategies needed to stand
              out in today’s competitive market.
              <br />
              <br />
              Our journey began with humble roots, connecting with small
              businesses and learning firsthand the unique challenges they face.
              This experience shaped our approach, allowing us to understand the
              raw core of every business and where they aspire to be. We believe
              that a strong brand is the foundation of success, and we work
              closely with each client to craft branding strategies that
              resonate with their target audience and reflect their values.
              <br />
              <br />
              Beyond branding, we bring expertise in digital marketing that
              ensures your brand reaches the right people, in the right places,
              at the right time. Our web and app development services enhance
              customer experience, helping you engage with your audience across
              platforms. From high-converting websites to user-friendly apps, we
              tailor solutions that fit your business model.
              <br />
              <br />
              What sets us apart is the magnitude of our global talent network.
              In addition to our core team, we collaborate with a wide array of
              professionals scattered across the world, giving us the capability
              to deliver outstanding services and materials no matter where our
              clients are based. This global reach ensures that we are always on
              the pulse of the latest industry trends and can provide our
              clients with diverse, innovative solutions that are both timely
              and impactful.
              <br />
              <br />
              Strategic thinking. Bold solutions. Unmatched customer experience.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
