import React, { useEffect } from "react";
import AOS from "aos";
import "./index.scss";
import mbdFooter from "../../../resources/images/svg/martley_full_logo.svg";

import SignatureSVG from "resources/images/svg/mva-brand-logo.svg";
import { Socmed } from "..";
import { Discord, DiscordBtn } from "components/_ui/Buttons";
import ScrollUp from "components/_ui/ScrollUp/ScrollUp";

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  return (
    <div id="visualarts" className="footer">
      <div
        className="component"
        data-aos="fade-up"
        data-aos-offset="-200"
        data-aos-duration="700"
      >
        <div className="inner-wrap">
          <div className="col col__1">
            <div className="deck deck__1">
              <div className="SocMed">
                <img src={mbdFooter} />
              </div>
            </div>
          </div>

          <div className="col col__2">
            <div className="deck deck__1">
              <div className="SocMed d-flex justify-content-center">
                <Socmed />
              </div>

              <div className="contacts">
                <a href="mailto:hello@martleybizdesign.com">
                  hello@martleybizdesign.com
                </a>
              </div>
              {/* <div className="contacts">
                <a href="mailto:growth@martleybizdesign.com">
                  growth@martleybizdesign.com
                </a>
              </div> */}
            </div>
          </div>

          <div className="col col__3">
            <div className="deck deck__1">
              <div className="meta-zombie">
                <h6>Pimp your Metaverse Identity.</h6>
                <h6>Catch the Undead on vacation!</h6>
              </div>

              <div className="zhd">
                <a href="https://zombieholidaynft.com/">zombieholidaynft.com</a>
              </div>

              <div className="discord-btn">
                <DiscordBtn />
              </div>
            </div>
          </div>
        </div>
        <div className="inner-wrap">
          <div className="footer-copy">
            <h4>©Martley™ Business Design. All Rights Reserved.</h4>
          </div>
          <ScrollUp />
        </div>
      </div>
    </div>
  );
};

export default Footer;
