import { Contact } from "components/_pages";
import Success from "components/_pages/Contact/Success";
import Subscribe from "components/_pages/Subscribe";

import SubscriptionSuccess from "components/_pages/Subscribe/SubscriptionSuccess";

import React from "react";

const Homepage = React.lazy(() => import(`components/_pages/Homepage`));
const AboutUs = React.lazy(() => import(`components/_pages/AboutUs`));
// const UnderDev = React.lazy(()=>import(`components/_pages/UnderDev`));

export const meta = {
  home: [
    {
      name: "description",
      content:
        "We build strategic brand identities for businesses so that they may remain relevant to their target customers and their top choice in the face of competition.",
    },
    {
      name: "keywords",
      content:
        "app developer, brand identity specialist, brand strategy consultant, branding consultant, business marketing, business process outsourcing, design agency, graphic design services, marketing consultant, real estate branding, business consultant, strategy and design",
    },
  ],
  contactUs: [
    {
      name: "description",
      content:
        "Let us co-create your business. Discuss opportunities and identify strategies that will work",
    },
    {
      name: "keywords",
      content: "",
    },
  ],
  subscribe: [
    {
      name: "description",
      content:
        "Stay on our radar for insights, exclusive discounts, monthly offers, and more",
    },
    {
      name: "keywords",
      content: "",
    },
  ],
};

export const title = {
  home: "Brand & Marketing Strategy",
  contactUs: "Contact Us",
  subscribe: "Sign Up",
};

export const ROUTES_CONFIG = [
  {
    path: "/",
    exact: true,
    title: title.home,
    meta: meta.home,
    component: <Homepage />,
  },
  {
    path: "/home",
    component: <Homepage />,
  },
  {
    path: "/offers",
    component: <Homepage />,
  },
  {
    path: "/feedbacks",
    component: <Homepage />,
  },
  {
    path: "/about",
    component: <AboutUs />,
  },
  {
    path: "/contact-us",
    title: title.contactUs,
    meta: meta.contactUs,
    component: <Contact />,
  },
  {
    path: "/message-sent",
    title: title.contactUs,
    meta: meta.home,
    component: (
      <Contact>
        <Success />
      </Contact>
    ),
  },
  {
    path: "/sign-up",
    title: title.subscribe,
    meta: meta.subscribe,
    component: <Subscribe />,
  },
  {
    path: "/subscribed",
    title: title.contactUs,
    meta: meta.home,
    component: (
      <Subscribe>
        <SubscriptionSuccess />
      </Subscribe>
    ),
  },
  {
    component: <h1>404</h1>,
  },
];
